export const environment = {
  production: false,
  stage: false,
  apiUrl: 'https://api-dev.runple.com',
  javaApiUrl: 'https://java-back-dev.runple.app',
  phpApiUrl: 'https://api-dev.runple.app',
  javaApiVersion: 'hq/v1',
  phpApiVersion: 'api/v1',
  javaAccountingApiVersion: 'accounting/v1',
};
